<template>
  <div
    v-if="translationData"
  >
    <b-button
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      v-b-tooltip.hover
      title="Modifier"
      variant="flat-warning"
      class="btn-icon rounded-circle"
    >
      <b-link
        v-b-modal="`modal-edit-translation-${translationData.id}`"
      >
        <feather-icon
          icon="SettingsIcon"
          size="20"
        />
      </b-link>
    </b-button>
    <b-modal
      :id="`modal-edit-translation-${translationData.id}`"
      cancel-variant="outline-primary"
      ok-title="Modifier"
      cancel-title="Annuler"
      centered
      title="Mise à jour de la traduction"
      @ok="updateTranslation(translationData)"
    >
      <b-form
        @submit.prevent
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Langue"
              label-for="h-title"
              label-cols-md="4"
            >
              {{ translationData.lang }}
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Clé (en anglais)"
              label-for="h-name"
              label-cols-md="4"
            >
              {{ translationData.name }}
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Valeur (traduction)"
              label-for="h-value"
              label-cols-md="4"
            >
              <b-form-input
                v-model="translationData.value"
                name="h-value"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BModal, VBModal, BForm, BFormGroup,
  BCol, BRow, BLink, VBTooltip,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BCol,
    BRow,
    BFormInput,
    BLink,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    translationData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    updateTranslation(translationData) {
      this.$store.dispatch('translation/updateTranslation', { translaId: translationData.id, body: translationData })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
