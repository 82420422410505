<template>
  <div
    v-if="$store.state.auth.role === 1"
  >
    <b-card no-body>
      <!-- Table Container Card -->
      <div
        class="mb-0"
      >
        <b-card-header>
          <b-col
            md="3"
          >
            <label>Langue</label>
            <v-select
              v-model="langFilter"
              :options="langOptions"
              class="w-100"
            />
          </b-col>
          <!-- Extract button -->
          <b-col
            sm="6"
            md="3"
            class="d-flex align-items-center justify-content-center mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
            >
              <download-excel
                :data="translationsList"
                :fields="fieldsExport"
                :name="`translations ${langFilter}`"
                :header="`Translation List`"
                :before-generate="startDownload"
                :before-finish="finishDownload"
              >
                <feather-icon
                  v-if="!isLoadingExport"
                  icon="DownloadIcon"
                  class="mr-1"
                />
                <span v-if="!isLoadingExport">Exporter la liste</span>
                <b-spinner
                  v-if="isLoadingExport"
                  label="Loading..."
                  class="mr-1"
                  small
                />
                <span
                  v-if="isLoadingExport"
                >Chargement...</span>
              </download-excel>
            </b-button>
          </b-col>
          <b-col
            sm="6"
            md="3"
            class="d-flex align-items-center justify-content-center mt-1"
          >
            <modal-new-translation :lang-options="langOptions" />
          </b-col>
        </b-card-header>

        <b-table
          v-if="translationsList.length > 0"
          :items="translationsList"
          responsive
          sticky-header
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          empty-text="Aucune traduction trouvée"
          :sort-desc.sync="isSortDirDesc"
          style="min-height: 60vh; max-width: 100vw;"
        >
          <template #cell(lang)="data">
            <b-link
              class="font-weight-bold d-block link-hover"
            >
              {{ data.item.lang }}
            </b-link>
          </template>

          <template #cell(name)="data">
            <small>
              {{ data.item.name }}
            </small>
          </template>

          <template #cell(value)="data">
            <small>
              {{ data.item.value }}
            </small>
          </template>

          <template #cell(action)="data">
            <div class="d-flex justify-content-end">
              <modal-edit-translation
                :translation-data="data.item"
              />
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-tooltip.hover
                title="Supprimer"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="deleteTranslation(data.item)"
              >
                <feather-icon
                  icon="DeleteIcon"
                  size="20"
                />
              </b-button>
            </div>
          </template>

        </b-table>
        <div
          v-else
          class="text-center"
        >
          <b-spinner
            v-if="isLoading"
            class="mt-5 mb-5"
          />
        </div>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ dataMeta.from }} à {{ dataMeta.to }} sur {{ dataMeta.of }} traductions</span>
            </b-col>
            <!-- Pagination -->
            <!-- <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col> -->
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BButton,
  BLink, BSpinner, VBTooltip, BCardHeader,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ModalNewTranslation from './ModalNewTranslation.vue'
import ModalEditTranslation from './ModalEditTranslation.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BSpinner,
    vSelect,
    BCardHeader,
    ModalNewTranslation,
    ModalEditTranslation,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      isLoadingExport: false,
      searchField: null,
      translationsList: [],
      totaltranslations: null,
      translationsListTotal: null,
      tableColumns: [
        { key: 'lang', label: 'Langue', sortable: true },
        { key: 'name', label: 'Clé (en anglais)', sortable: true },
        { key: 'value', label: 'Valeur (traduction)', sortable: true },
        { key: 'action', label: 'Actions', sortable: false },
      ],
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      sortBy: 'name',
      searchQuery: null,
      isSortDirDesc: false,
      langOptions: [],
      langFilter: 'FR',
      // export
      fieldsExport: {
        Lang: 'lang',
        Key: 'name',
        Value: 'value',
      },
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.translationsList.length ? this.translationsList.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totaltranslations,
      }
    },
  },
  watch: {
    perPage(newData) {
      this.perPage = newData
      this.currentPage = 1
      this.getTranslations()
    },
    currentPage(newData) {
      this.currentPage = newData
      this.getTranslations()
    },
    isSortDirDesc(newData) {
      this.currentPage = 1
      this.isSortDirDesc = newData
      this.getTranslations()
    },
    sortBy(newData) {
      this.currentPage = 1
      this.sortBy = newData
      this.getTranslations()
    },
    searchQuery(newData) {
      this.currentPage = 1
      this.searchQuery = newData
      this.getTranslations()
    },
    langFilter(newData) {
      this.langFilter = newData
      this.mutateFilter()
    },
  },
  created() {
    this.getTranslations()
  },
  methods: {
    resolveActiveVariant(data) {
      if (data) return 'success'
      if (!data) return 'danger'
      return 'dark'
    },
    getTranslations() {
      this.isLoading = true
      store.dispatch('translation/findAllTranslations')
        .then(response => {
          const array = []
          this.translationsListTotal = response.sort((a, b) => a.name.localeCompare(b.name))
          this.translationsList = response.filter(translation => translation.lang === this.langFilter).sort((a, b) => a.name.localeCompare(b.name))
          this.totalTranslations = response.length
          this.isLoading = false
          response.map(translate => {
            array.push(translate.lang)
            return translate.lang
          })
          this.langOptions = [...new Set(array)]
        })
        .catch(err => {
          console.log('err', err)
          this.isLoading = false
          this.translationsList = []
        })
    },
    mutateFilter() {
      this.translationsList = this.translationsListTotal.filter(translation => translation.lang === this.langFilter)
      this.totalTranslations = this.translationsList.length
    },
    deleteTranslation(transla) {
      this.$swal({
        title: 'Êtes-vous sûr de vouloir supprimer ?',
        text: 'Cette action est irréversible!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer!',
        cancelButtonText: 'Annuler',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('translation/deleteTranslation', transla.id)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Supprimé!',
                text: 'L\'évènement a bien été supprimé',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
          this.getTranslations()
        }
      })
    },
    // export
    startDownload() {
      this.isLoadingExport = true
    },
    finishDownload() {
      this.isLoadingExport = false
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
