<template>
  <div>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-new-translation
      variant="outline-primary"
    >
      Ajouter une traduction
    </b-button>
    <b-modal
      id="modal-new-translation"
      cancel-variant="outline-primary"
      ok-title="Ajouter"
      cancel-title="Annuler"
      centered
      title="Nouvelle traduction"
      @ok="addTranslation(translationData)"
    >
      <b-form
        @submit.prevent
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Langue"
              label-for="h-title"
              label-cols-md="4"
            >
              <b-form-select
                v-model="translationData.lang"
                name="h-title"
                :options="langOptions"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Clé (en anglais)"
              label-for="h-name"
              label-cols-md="4"
            >
              <b-form-input
                v-model="translationData.name"
                name="h-name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Valeur (traduction)"
              label-for="h-value"
              label-cols-md="4"
            >
              <b-form-input
                v-model="translationData.value"
                name="h-value"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BModal, VBModal, BForm, BFormGroup,
  BCol, BRow, BFormSelect,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BCol,
    BRow,
    BFormSelect,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    langOptions: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      translationData: {
        lang: null,
        name: null,
        value: null,
      },
    }
  },
  methods: {
    addTranslation(translationData) {
      // add name of this new translation in all langage (one row by langage), and add the value in choosen langage
      this.langOptions.forEach(async langage => {
        const newTransla = {
          lang: langage,
          name: translationData.name,
        }
        if (langage === translationData.lang) {
          await store.dispatch('translation/createTranslation', translationData)
            .then(response => {
              if (!response) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Cette clé de traduction existe déjà',
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        } else {
          await store.dispatch('translation/createTranslation', newTransla)
        }
      })
      this.translationData = {
        lang: null,
        name: null,
        value: null,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
